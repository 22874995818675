<!-- 需求下单 -->
<template>
  <div class="container">
    <el-card>
      <h3>需求接单</h3>
      <div class="el-descriptions_box">
        <div style="text-align: left">
          <el-image
            fit="contain"
            style="width: 150px; height: 150px"
            :src="jsonData.projectImg"
            :previewSrcList="[jsonData.projectImg]"
          ></el-image>
        </div>
        <el-descriptions>
          <el-descriptions-item label="工程名称">{{
            jsonData.projectName
          }}</el-descriptions-item>
          <el-descriptions-item label="工程简述">{{
            jsonData.projectDec
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions>
          <el-descriptions-item label="施工地点"
            >{{ jsonData.projectProvince }}{{ jsonData.projectCity
            }}{{ jsonData.projectCountry
            }}{{ jsonData.projectAddress }}</el-descriptions-item
          >
        </el-descriptions>
        <el-descriptions>
          <el-descriptions-item label="支付方式">{{
            jsonData.payTypeCode == 0
              ? "完工付"
              : jsonData.payTypeCode == 1
              ? "现结"
              : jsonData.payTypeCode == 2
              ? "日结"
              : jsonData.payTypeCode == 3
              ? "月结"
              : "季付"
          }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{
            jsonData.publishTime
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions>
          <el-descriptions-item label="施工备注">{{
            jsonData.projectRemark
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="facility">
        <div class="tile_box">
          <div class="title">设备需求</div>
        </div>
        <div class="list">
          <div
            class="list-item"
            v-for="(item, index) in jsonData.details"
            :key="index"
          >
            <el-descriptions size="small" border>
              <el-descriptions-item label="机械名称">{{
                item.vehicleName
              }}</el-descriptions-item>
              <el-descriptions-item label="机械类型"
                >{{ item.firstLevelType }}/{{
                  item.secondLevelType
                }}</el-descriptions-item
              >
              <el-descriptions-item label="机械型号">{{
                item.vehicleModel
              }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions size="small" border>
              <el-descriptions-item label="机械品牌">{{
                item.vehicleBrand
              }}</el-descriptions-item>
              <el-descriptions-item label="租赁方式">{{
                item.leaseUnit
              }}</el-descriptions-item>
              <el-descriptions-item label="保险方式">{{
                item.insure ? "无" : "有"
              }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions size="small" border>
              <el-descriptions-item label="进场时间">{{
                item.workTimeStart
              }}</el-descriptions-item>
              <el-descriptions-item label="租赁数量"
                >{{ item.needNumber }}台</el-descriptions-item
              >
              <el-descriptions-item label="租赁费用">{{
                item.vehicleOffer
              }}</el-descriptions-item>
              <el-descriptions-item
                v-if="item.leaseType != '工作量'"
                label="工作量"
                >{{ item.leaseWork }}{{ item.leaseUnit }}</el-descriptions-item
              >
            </el-descriptions>
            <div class="sendcar">
              <el-button
                type="primary"
                size="small"
                @click="openSendCar(item, index)"
                >派车 ({{
                  submitData.cereOrderDetails[index]
                    ? submitData.cereOrderDetails[index].vehicleIds.length
                    : 0
                }})</el-button
              >
            </div>
          </div>
        </div>
        <div class="total_price">
          <h3>
            合计：<span style="color: red">￥{{ price ? price : "0.00" }}</span>
          </h3>
        </div>
        <div class="btn">
          <el-button size="small" @click="$router.back(-1)">返回</el-button>
          <el-button size="small" @click="submit">提交</el-button>
        </div>
      </div>
    </el-card>
    <!-- 车辆信息 -->
    <el-dialog
      title="车辆信息"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-scrollbar class="carlist">
        <div class="carlist_item" v-for="(item, index) in carList" :key="index">
          <el-checkbox-group v-model="item.isChecked">
            <el-checkbox size="medium"></el-checkbox>
          </el-checkbox-group>
          <div>
            <el-image :src="item.vehicleImg"></el-image>
          </div>
          <div class="car_content">
            <el-descriptions size="small">
              <el-descriptions-item label="车辆名称">{{
                item.vehicleName
              }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions size="small">
              <el-descriptions-item label="类型"
                >{{ item.firstLevelType }}/{{
                  item.secondLevelType
                }}</el-descriptions-item
              >
              <el-descriptions-item label="型号">{{
                item.vehicleModel
              }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions size="small">
              <el-descriptions-item label="地址"
                >{{ item.vehicleProvince }}{{ item.vehicleCity
                }}{{ item.vehicleCounty }}</el-descriptions-item
              >
            </el-descriptions>
          </div>
        </div>
      </el-scrollbar>
      <div class="btn">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" @click="addCar">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getById, getVehicleInfoByOrder, getOffer, submit } from "@/api/car";

export default {
  data() {
    return {
      jsonData: {},
      dialogVisible: false,
      carList: [],
      maxNum: 0,
      submitData: {
        cereOrderDetails: [
          {
            count: 0,
            requireDetailId: 0,
            vehicleIds: [],
          },
        ],
        requiredId: null,
      },
      carIndex: null,
      newCarList: [],
      price: "",
    };
  },
  created() {
    getById(this.$route.query.id).then((res) => {
      this.jsonData = res.data;
    });
  },
  methods: {
    openSendCar(item, index) {
      if (
        item.needNumber <
        this.submitData?.cereOrderDetails[index]?.vehicleIds?.length + 1
      )
        return this.$message({
          message: "不能超出所需车辆",
          type: "warning",
        });

      if (
        this.submitData.cereOrderDetails[
          this.submitData.cereOrderDetails.length - 1
        ].vehicleIds.length > 0
      ) {
        this.submitData.cereOrderDetails.push({
          count: 0,
          requireDetailId: 0,
          vehicleIds: [],
        });
      }
      this.carIndex = index;
      this.maxNum = item.vechileNumber;
      // 需求详情和接单页面-未接单数量：needNumber
      this.requireDetailId = item.id;

      let data = {
        firstLevelType: item.firstLevelTypeId,
        secondLevelType: item.secondLevelTypeId,
      };
      getVehicleInfoByOrder(data).then((res) => {
        if (res.data.length == 0) {
          this.$message.warning("暂无相关车辆信息");
          return;
        }
        this.dialogVisible = true;
        if (this.submitData?.cereOrderDetails[this.carIndex] == undefined) {
          this.submitData.cereOrderDetails.push({
            count: 0,
            requireDetailId: 0,
            vehicleIds: [],
          });
        }
        res.data.forEach((item) => {
          item.isChecked = false;
          this.submitData?.cereOrderDetails[this.carIndex]?.vehicleIds.forEach(
            (v) => {
              if (item.id == v) {
                item.isChecked = true;
              }
            }
          );
        });
        this.carList = res.data;
      });
    },
    // hendChange(e) {
    //   if (e.isChecked == true) {
    //     this.submitData.cereOrderDetails[this.carIndex].vehicleIds.push(e.id)
    //     this.submitData.cereOrderDetails[
    //       this.carIndex
    //     ].requireDetailId = this.requireDetailId
    //     this.submitData.cereOrderDetails[
    //       this.carIndex
    //     ].count = this.submitData.cereOrderDetails[
    //       this.carIndex
    //     ].vehicleIds.length
    //   } else {
    //     this.submitData.cereOrderDetails[
    //       this.carIndex
    //     ].vehicleIds = this.submitData.cereOrderDetails[
    //       this.carIndex
    //     ].vehicleIds.filter((v) => v != e.id)
    //     this.submitData.cereOrderDetails[
    //       this.carIndex
    //     ].count = this.submitData.cereOrderDetails[
    //       this.carIndex
    //     ].vehicleIds.length
    //   }
    // },
    addCar() {
      this.submitData.cereOrderDetails[this.carIndex].vehicleIds = [];
      this.carList.forEach((item) => {
        if (item.isChecked == true) {
          this.submitData.cereOrderDetails[this.carIndex].vehicleIds.push(
            item.id
          );
        }
      });
      this.submitData.cereOrderDetails[this.carIndex].requireDetailId =
        this.requireDetailId;
      this.submitData.cereOrderDetails[this.carIndex].count =
        this.submitData?.cereOrderDetails[this.carIndex]?.vehicleIds.length || 0;
      if (
        this.submitData?.cereOrderDetails[this.carIndex]?.vehicleIds.length >
        this.maxNum
      ) {
        this.$message.warning("不能超过设备数量最大值");
        return;
      }
      this.submitData.requiredId = this.$route.query.id;
      getOffer(this.submitData).then((res) => {
        this.price = res.data.amountTotal.toFixed(2);
      });
      this.dialogVisible = false;
    },
    submit() {
      var isSubmit = true;
      this.submitData?.cereOrderDetails.forEach((item) => {
        if (item?.vehicleIds.length <= 0 && isSubmit) {
          isSubmit = true;
        } else {
          isSubmit = false;
        }
      });

      if (isSubmit === true) {
        this.$message.warning("最少要有一辆车");
        return;
      }
      let vehicleIds =
        this.submitData.cereOrderDetails[
          this.submitData.cereOrderDetails.length - 1
        ]?.vehicleIds;
      if (vehicleIds.length <= 0) {
        this.submitData.cereOrderDetails.pop();
      }
      let submitData  = this.submitData
      submitData.cereOrderDetails =  submitData.cereOrderDetails.filter(e=>e.vehicleIds && e.vehicleIds.length)
      submit(submitData).then((res) => {
        this.$message.success("提交成功");
        this.$router.go(-2);
      });
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  width: 110px;
}
::v-deep .el-descriptions__body {
  // background-color: #f4f4f4;
}
.el-card {
  margin-top: 20px;
  text-align: center;

  .facility {
    text-align: left;
    margin-top: 30px;
    .tile_box {
      border-bottom: 2px solid #dddddd;
      position: relative;
      .title {
        position: absolute;
        top: -30px;
        font-size: 18px;
        font-weight: bold;
        border-bottom: 2px solid #fcb110;
        width: 72px;
        line-height: 30px;
      }
    }
    .list {
      .list-item {
        margin-top: 20px;
        .sendcar {
          margin-top: 10px;
          text-align: right;
        }
      }
    }
  }
}

.btn {
  margin-top: 40px;
  text-align: center;
  .el-button {
    width: 150px;
  }
  .el-button:nth-child(1) {
    background-color: #ffffff;
    color: #fcb110;
    border: 1px solid #fcb110;
  }
  .el-button:nth-child(2) {
    background-color: #fcb110;
    color: #fff;
    border: 1px solid #fcb110;
  }
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
.carlist {
  height: 350px;
  .carlist_item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    padding: 10px;
    .el-image {
      margin: 0 20px;
      width: 100px;
      height: 100px;
    }
    .car_content {
    }
  }
}
</style>